import React, { useEffect } from "react"

import Layout from "../components/layout"
import { ThemeProvider } from "styled-components"

const theme = {
  color: "#111",
  headerFontSize: "14px",
  footerTitleSize: "15px",
  footerTextSize: "14px",
}

const IndexPage = () => {
  useEffect(() => {
    console.log("I AM HERE!!")
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <Layout />
    </ThemeProvider>
  )
}

export default IndexPage
